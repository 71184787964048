"use strict";

(function($) {
	$(document).on("click", ".living-options__selector .fl-button-group-button a", function() {
		var livingOption = $(this).attr("href").replace("#", "");

		$(".living-options__selector .fl-button-group-button a").removeClass("living-options__selector--active");
		$(this).addClass("living-options__selector--active");

		$(".living-options").hide();
		$(".living-options__" + livingOption).show();
	});

	/*jshint quotmark: false */
	if(window.location.hash === "#independent-living" || window.location.hash === "#assisted-living" || window.location.hash === "#short-term-stay" ) {
		$(".living-options__selector .fl-button-group-button").has('a[href="' + window.location.hash + '"]').find("a").trigger("click");
	} else {
		$(".living-options__selector .fl-button-group-button:first-child a").trigger("click");
	}
	/*jshint quotmark: true */

	jQuery(document).ready(function($) {
		// show the no-events div if there are no events
		if (!$.trim( $('.tribe-events-pro-photo .tribe-common-g-row').html() ).length) {
			$('.no-events').show();
		}
	});

})(jQuery);
